@tailwind base;
@tailwind components;
@tailwind utilities;

@media all {
	.print-only {
		display: none;
	}
}

@media print {
	.print-hidden {
		display: none;
	}
	.print-only {
		display: initial;
	}
}
